.confirm-account-container {
  color: #FFF;
  margin-top: 200px;
  text-align: center;
  a{
    max-width: 300px;
    margin: auto;
    display: block;
    width: 100%;
  }
}

.error {
  color: red;
}

.btn.loading {
  background-color: #c7c7c7;
  cursor: default;
}

.confirm-account-successfull {
  font-size: 2rem;
}
