header {
  padding: 21px;
  position: absolute;
  width: 100%;
  z-index: 980;
}

.header-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.navbar-left-container {
  flex-direction: row;
}

.navbar-logo-container {
  width: 103px;
  height: 31px;
}

.navbar-logo {
  height: 100%;
  width: 100%;
}

.navbar-sign-in-container {
  border: 1.5px solid #007BFF;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 700;
}

.navbar-sign-in-container {
  border: 1.5px solid #007BFF;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 700;
}

.navbar-sign-in-delimiter {
  border-left: 1.5px solid #007BFF;
  font-size: 0;
  margin: 10px 0;
}

.navbar-sign-in-element {
  color: #00FF85;
  margin: 0;
  height: 60px;
  max-width: 130px;
  width: 260px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 0;
}
.navbar-sign-in-element:hover {
  color: #00FF85;
  cursor: pointer;
  text-decoration: none;
}

.navbar-links-container {
  background: #000;
  left: 0;
  position: absolute;
  right: 0;
  top: -2000px;
  transition: top 0.5s;
  z-index: 970;
}
.navbar-links-container.opened {
  top: 89px;
}

.navbar-links-desktop-container {
  display: none;
}

.navbar-link {
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 10px;
}
.navbar-link:hover {
  color: #00FF85;
  cursor: pointer;
  text-decoration: none;
}

.hamburger {
  height: 26px;
  margin-right: 20px;
  position: relative;
  width: 26px;
}

.menu__btn.opened > span {
  transform: rotate(45deg);
}
.menu__btn.opened > span::before {
  top: 0;
  transform: rotate(0);
}
.menu__btn.opened > span::after {
  top: 0;
  transform: rotate(90deg);
}

.menu__btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #FFF;

  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

.header-background {
  background-color: #000;
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.5s;
}
.header-background.opened {
  display: block;
  z-index: 950;
}

.header-sign-in-container {
  align-items: center;
  justify-content: center;
}

.navbar-link.active {
  border-color: #007BFF;
}
.navbar-link {
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  margin: 0 10px;
  border-bottom: 1px solid transparent;
}
.navbar-link:hover {
  color: #26de81;
  text-decoration: none;
}
@media (min-width:600px)  {
  .navbar-logo-container {
    width: 210px;
    height: 62px;
  }

  .navbar-sign-in-container {
    border-width: 2px;
  }

 

  .menu__btn {
    top: 31px;
    width: 34px;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    height: 3px;
  }

  .navbar-links-container.opened {
    top: 104px;
  }

  .navbar-link {
    font-size: 1.25rem;
  }
}

@media (min-width:1600px)  {
  header {
    padding: 40px 150px;
  }

  .hamburger {
    display: none;
  }

  .navbar-logo-container {
    width: 234px;
    height: 69px;
  }

 
  .navbar-links-container,
  .navbar-links-container.opened {
    display: none;
  }

  .navbar-links-desktop-container {
    display: block;
    display: flex;
    flex-direction: row;
  }
  
 
}