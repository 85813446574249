.withdraw-wrapper {
  margin-top: 89px;
}

.withdraw-loading {
  color: #fff;
  font-size: 1rem;
  margin: 95px 20px 5px 20px;
}

.withdraw-error-message {
  color: #ff1e1e;
  font-size: 1rem;
  margin: 10px;
}

.withdraw-address {
  min-width: 100px;
}

.withdraw-coin-error-message {
  color: #ff1e1e;
  font-size: 1rem;
  margin: 10px;
}

.withdraw-selector-block {
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
}

.withdraw-completed-message {
  color: #00FF85;
  font-size: 1rem;
  margin: 5px 20px;
}

.withdraw-currency-block {
  flex-direction: column;
  background: #1F2533;
  width: 500px;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  border: 3px solid transparent;
  cursor: pointer;
}
 
.withdraw-currency-block.selected{
 border-color:#007BFF ;
}
.withdraw-currency-logo {
  height: 65px;
  width: 65px;
}

.withdraw-currency-name {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
}
.withdraw-control-block {
  flex-direction: row;
  margin: 100px 0 80px;
}

.withdrawContent {
  width: 100%;
  max-width: 750px;
}

.withdrawBrainImag {
  width: 100%;
  align-items: flex-end;
}

.withdrawBrainImag img {
  width: 100%;
  max-width: 600px;
}
.withdrawal-fee {
  display: flex;
  flex-direction: row; 
  margin: 5px 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 40px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.primary-color{
  color: #00FF85;
}
.withdrawImg {
  flex-direction: row;
}

.withdrawImg img {
  width: 100%;
  max-width: 35px;
  margin-right: 20px;
}
input.withdraw-input.form-control {
  width: 100%;
  background: #1F2533;
  border: none!important;
}
.withwraw-amount-warning {
  color: red;
  margin: 5px 20px;
}

.withdraw-input {
  color: #495057;
  display: block;
  margin: 5px 0;
  width: calc(100% - 40px);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
 
.withdraw-coin-white-message {
  color: #fff;
  font-size: 15px;
}
.withdraw-money-button {
  background: #007BFF;
  box-shadow: 0px 3px 10px rgba(0, 123, 255, 0.5);
  border-radius: 3px;
  max-width: 310px;
  height: 60px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 30px 0 25px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.withdraw-completed {
  color: #fff;
  font-size: 1rem;
  margin: 30px 20px 0;
}

.withdraw-coin-network {
  min-width: 140px;
}

.withdraw-element-id {
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

@media (min-width:600px) {
  .withdraw-wrapper {
    margin-top: 104px;
  }

  .withdraw-loading {
    font-size: 1.25rem;
  }
  
  .withdraw-error-message {
    font-size: 1.25rem;
  }
  
  .withdraw-completed-message {
    font-size: 1.25rem;
  }
 
 
  
  .withwraw-amount-warning {

    // color: red;
    // margin: 5px 20px;
  }
  
  .withdraw-input {
    // color: #495057;
    // display: block;
    // margin: 5px 20px;
    // width: calc(100% - 40px);
    // height: calc(1.5em + 0.75rem + 2px);
    // padding: 0.375rem 0.75rem;
    // font-size: 1rem;
    // font-weight: 400;
    // line-height: 1.5;
    // background-color: #fff;
    // background-clip: padding-box;
    // border: 1px solid #ced4da;
    // border-radius: 0.25rem;
    // transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
 

  .withdraw-completed-container {
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    text-align: center;
  }

  .withdraw-completed {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .withdraw-wrapper {
    margin-top: 160px;
  }

  .withdraw-container {
    margin-left: auto;
    margin-right: auto;
    // width: 800px;
  }

  .withdraw-completed-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    max-width: 600px;
  }
 
}
