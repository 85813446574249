* {
  box-sizing: border-box;
}

.pp-container {
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  max-width: 1620px;
  padding: 10px;
  white-space: pre-line;
  width: 100%;
}

.pp-title {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 1rem 1rem 0.5rem 1.2rem;
  flex-direction: revert;
  justify-content: space-between;
  .pp-title-secondary {
    font-size: 20px;
    line-height: 34px;
    color: #ffffff;
    padding: 8px;
    flex-direction: revert;
    justify-content: space-between;
  }
}

.pp-text {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  padding: 20px;
  display: inline-block;
}
.text-privacy {
  text-align: justify;
}
.footer-links-div {
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
.footer-links-div:first-of-type {
  margin-left: 2.5rem;
  @media (max-width: 437px) {
    margin-left: 1rem;
  }
}
.footer-links {
  font-size: 0.9rem;
  cursor: pointer;
  @media (max-width: 700px) {
    font-size: 0.7rem;
  }
}
.title {
  padding-top: 1rem;
  font-size: 1.4rem;
}
.list-items {
  padding-left: 3rem;
  list-style-type: disc;
}
.fittedinlinestyle {
  padding-left: 0.2rem;
}
