.deposit-currency-block{

} 

.deposit-currency-name {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px; 
  text-align: center;
  color: #FFFFFF;
  margin: 15px 0;
}

.deposit-history-block {
  color: #fff;
  margin: 10px 20px;
  overflow-x: auto;
}

.deposit-history-element {
  flex-direction: row;
  justify-content: space-between;
}

.deposit-history-title {
  font-size: 1.5rem;
  margin: 20px;
  text-align: center;
}

.deposit-table {
  width: 900px;
}

.deposit-table thead th{
  color: #fff;
  font-size: 1rem;
  text-align: center;
}

.deposit-element-date {
  min-width: 200px;
}

.deposit-coin-network {
  min-width: 100px;
}

.deposit-currency-wrapper {
  flex-direction: column;
  background: #1F2533;
  width: 500px;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  border: 3px solid transparent;
}

.deposit-currency-logo-container {
  justify-content: center;
  align-items: center;
}
.deposit-currency-wrapper.selected {
  border: 3px solid #007BFF;
}
.deposit-currency-block {
  width: 100%;
  height: 100%;
  padding: 15px;
  cursor: pointer;
}
.deposit-currency-logo-container img {
  height: 60px;
  width: 60px;
}

.deposit-currency-logo {
  height: 100%;
  width: 100%;
}
.depositWrapper {
  flex-direction: row;
  font-size: 18px;
  line-height: 36px;
  color: #FFFFFF;
  align-items: center;
}
.deposit-copy-to-clipboard-icon-container {
  cursor: pointer;
  height: 24px;
  margin-left: 15px;
  width: 24px;
}

.deposit-copy-to-clipboard-icon-block {
  height: 100%;
  width: 100%;
}

.deposit-copy-to-clipboard-icon {
  fill: #007BFF;
}
.deposit-copy-to-clipboard-icon-container:active .deposit-copy-to-clipboard-icon {
  fill: #00FF85;
}

@media (min-width:600px) {
 

  .deposit-history-element {
    font-size: 1.25rem;
  }

  .deposit-history-title {
    font-size: 2rem;
  }

  
}

@media (min-width:1600px) {
 
  .deposit-history-element {
    font-size: 1.6rem;
  }

  .deposit-history-title {
    font-size: 3rem;
  }
 
}
