.login-container {
  display: grid !important;
}

.login-container a {
  color: #00FF85;
  text-decoration: underline;
}

.form-access {
  background: #1F2433;
  margin-left: auto;
  margin-right: auto;
}

.dont-have-account.bottom {
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
}

.dont-have-account.top {
  display: none;
}

.error {
  color: red;
}

.btn.loading {
  background-color: #c7c7c7;
  cursor: default;
}

.logo-wrapper {
  display: none;
}

@media (min-width:600px) {
  .dont-have-account.bottom {
    display: none;
  }

  .dont-have-account.top {
    display: block;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .form-access {
    width: 80vw;
  }
}

@media (max-width: 991px) {
  .login-container {
    height: auto!important;
    margin-top: 100px;
}
}
@media (min-width: 1600px) {
  .login-container {
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .login-container .block {
    aspect-ratio: 1;
  }

  .form-access {
    align-items: flex-start;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 10px 0 #131722;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    min-width: 350px;
    padding: 30px;
    width: 650px;
  }

  .form-access span {
    font-size: 3rem;
    text-align: start;
  }

  .form-access form {
    border: 0 !important;
    box-shadow: none !important;
    display: grid;
    justify-content: start;
    width: 100%;
  }

  .form-control {
    border: 1px solid #fff !important;
    font-size: 1.5rem !important;
    padding: 1.8rem !important;
    width: 473px;
  }

  .login-submit-btn {
    font-size: 1.5rem;
    line-height: 34px;
    margin-top: 50px;
    width: 260px !important;
  }

  .dont-have-account.top {
    margin-bottom: 53px;
    margin-top: 0;
    text-align: start;
  }

  .logo-wrapper{
    background: linear-gradient(234.75deg, #1F2433 -0.73%, rgba(0, 123, 255, 0.19) 101.05%);
    color: #fff;
    display: block;
    height: 80vh;
    max-height: 650px;
    overflow: hidden;
    position: relative;
  }

  .logo {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .logo-container {
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    position: relative;
    width: 273px;
  }

  .logo-percentage {
    font-size: 3rem;
    font-weight: 700;
    line-height: 40px;
    margin-top: 70px;
    text-align: center;
  }

  .logo-percentage-description {
    font-size: 1.5rem;
    text-align: center;
  }

  .logo-container-description {
    font-size: 1.5rem;
    margin-top: 35px;
    text-align: center;
    text-transform: uppercase;
  }

  .logo-container-bottom-block {
    font-size: 1.25rem;
    margin-top: 42px;
    text-align: center;
    text-transform: uppercase;
  }

  .logo-container-bottom-icon {
    margin-left: 10px;
    margin-right: 10px;
  }
}

