@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  font-size: 14px;
}

div {
  display: flex;
  flex-direction: column;
}

.table-container {
  margin-top: 2rem;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #31383d;
  padding: 0.5rem;
  text-align: center;
  color: #f8f8f8;
}

.table thead th {
  background-color: #1d2532;
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: #242a33;
}

.table tbody tr:nth-child(odd) {
  background-color: #1d2330;
}

.table .green-text {
  color: #28a745;
}

.table .red-text {
  color: #dc3545;
}

.btc-symbol {
  font-family: "Segoe UI Symbol";
}

.wrapper {
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
}

.disclaimer-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9990;
}

.disclaimer-background {
  background: #000;
  height: 100vh;
  opacity: 0.8;
  position: absolute;
  width: 100vw;
  z-index: 9991;
}

.disclaimer-container {
  background: #1F2433;
  justify-content: space-between;
  left: 0;
  margin-left: 20px;
  margin-right: 20px;
  max-height: 650px;
  max-width: 1056px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9992;
}

.disclaimer-close-icon {
  color: #C4C4C4;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
}

.disclaimer-description {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.8rem;
  opacity: 0.6;
  text-align: justify;
}

.disclaimer-agree-container {
  align-items: center;
  color: #007BFF;
  flex-direction: row;
  font-size: 1.25rem;
  margin-top: 25px;
}

.disclaimer-agree-checkbox {
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.disclaimer-ok-btn {
  background: #007BFF;
  color: #fff;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  text-align: center;
  width: 186px;
}

.disclaimer-description .laptop {
  display: none;
}

.title-font {
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

.description-font {
  font-size: 1.5rem!important;
  line-height: 2rem!important;
  margin-top: 38px;
  text-align: justify;
  white-space: pre-wrap;
}
.description-font .green {
  color: #00FF85;
}

.simple-background {
  background-color: #1F2533;
}
.currencies-background-brain{
  max-width: 1200px!important;
}
.standart-padding {
  padding-left: 15px;
  padding-right: 15px;
}

// start hero container
.hero-container {
  justify-content: center;
  // margin-top: 89px;
  padding-top: 89px;
  min-height: 100vh;
  position: relative;
}

.hero-logo {
  display: none;
}

.hero-background {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: -1;
}

.hero-background-left {
  bottom: 0;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}

.hero-background-right {
  bottom: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.hero-background-brain {
  height: calc(100vh - 89px);
  left: 0;
  max-width: 200%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
  position: absolute;
  top: 89px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.4rem;
  text-transform: uppercase;
  z-index: 2;
}
.hero-title .green {
  color: #00FF85;
}

.hero-description {
  font-size: 1.25rem;
  margin-top: 30px;
  z-index: 2;
}

.hero-button {
  background: #007BFF;
  border: 1px solid #007BFF;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1.25rem;
  margin-top: 50px;
  padding: 14px;
  text-align: center;
  width: 100%;
  z-index: 2;
  position: relative;
}

.hero-button:hover:after {
 content: "";
 position: absolute;
 background-image: url("../../../assets/images/btnBg.svg");
 width: 600px;
  height: 200px;
  top: 0;
  left: -150px;
  right: 0;
  bottom: 0;
  background-size: cover;
  margin: auto;
}

.hero-button:hover {
  border: 1px solid #00FF85;
  box-shadow: 0px 10px 30px rgba(0, 123, 255, 0.5);
}
.hero-button-background-1,
.hero-button-background-2,
.hero-button-background-3 {
  display: none;
}
// end hero container

// block 2 start
.summary-container {
  justify-content: center;
  min-height: 100vh;
}

.summary-top-container {
  align-items: center;
}

.summary-top-container-left-block {
  font-size: 1.25rem;
}

.summary-top-container-right-block {
  font-size: 1rem;
  margin-top: 30px;
}

.summary-top-container-right-block-string {
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.summary-top-container-right-block-icon-container {
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.summary-top-container-right-block-icon.first {
  height: 25px;
  width: 27px;
}

.summary-top-container-right-block-icon.second {
  height: 16px;
  width: 36px;
}

.summary-top-container-right-block-icon.third {
  height: 20px;
  width: 29px;
}

.summary-top-container-right-block-description {
  font-size: 1.25rem;
  line-height: 1.2rem;
}

.summary-bottom-container {
  background: #131722;
  border-left: 5px solid #007BFF;
  margin-top: 35px;
  padding-top: 20px;
}

.summary-bottom-container {
  padding-left: 20px;
}

.summary-bottom-container-block-title {
  font-size: 1rem;
}

.summary-bottom-container-block-delimiter {
  border-top: 2px solid #007BFF;
  line-height: 10px;
  margin-top: 10px;
  width: 60px;
}

.summary-bottom-container-block-description {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.summary-bottom-container-block-or {
  font-size: 1rem;
}

.summary-bottom-container-block-percent {
  color: #00FF85;
}
.summary-bottom-container-block-percent-red {
  color: red;
}
// block 2 end

// block 3 start
.trading-track-container {
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

.trading-track-image-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  z-index: 2;
}
.trading-track-image {
  min-width: 1200px;
}
// block 3 end

// block 4 start
.what-is-it-tailored-for-container {
  justify-content: center;
  min-height: 100vh;
}
// block 4 end

// block 5 start
.profit-container {
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
// block 5 end

// block 6 start
.sharing-container {
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

.sharing-background {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.sharing-background-hand {
  max-width: 200%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 60%;
  width: 100%;
  z-index: 1;
}
.sharing-background-hand.wide {
  display: none;
}
// block 6 end

// block 7 start
.profit-shared-container {
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
// block 7 end

// block 8 start
.start-trading-container {
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 30px;
  padding-top: 30px;
}
// block 8 end

// block 9 start
.stop-trading-container {
  justify-content: center;
  min-height: 100vh;
}
.start-trading-container .link {
  text-decoration: underline;
}
// block 9 end

// block 10 start
.video-container {
  justify-content: center;
  min-height: 100vh;
  padding: 10px;
  position: relative;
}

.video-wrapper {
  aspect-ratio: 1.78;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  z-index: 3;
}
// block 10 end

// block 11 start
.strategy-container {
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
.strategy-container .link {
  text-decoration: underline;
}
// block 11 end

// block 12 start
.currencies-wrapper {}

.currencies-container {
  justify-content: center;
  max-height: 940px;
  max-width: 1920px;
  min-height: 100vh;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
}

.currencies-background {
  // position: absolute;
  bottom: 0;
  overflow: hidden;
  right: 0;
}

.currencies-background-brain {
  left: 0;
  max-width: 300%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
  position: absolute;
  top: 10%;
  width: 250%;
}

.currencies-block {
  background: #131722;
  margin-bottom: 20px;
  padding-bottom: 36px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  z-index: 1;
  border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.currencies-block.left {
  border-left: 10px solid #007BFF;
}
.currencies-block.right {
  border-left: 10px solid #00FF85;
}

.currencies-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.currencies-description,
.exchanges-description {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
}

.currencies-icon-container,
.exchanges-icon-container {
  margin-bottom: 15px;
  margin-right: 30px;
}

.exchanges-icon-container.first {
  min-width: 81px;
}
.exchanges-icon-container.second {
  min-width: 124px;
}
.exchanges-icon-container.third {
  min-width: 83px;
}

.exchanges-description {
  flex-direction: row;
  margin-top: 25px;
}

.currencies-icon {
  height: 40px;
  width: 40px;
}

.exchanges-icon {
  height: 25px;
}
// block 13 end

footer {
  font-size: 1.1rem;
  line-height: 1.4rem;
  padding: 30px 15px;
  text-align: justify;
}

.footer-disclaimer {
  margin-bottom: 20px;
  opacity: 0.6;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  {
  .wrapper {}

  .title-font {
    font-size: 3rem;
  }

  .description-font {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .standart-padding {
    padding-left: 40px;
    padding-right: 40px;
  }

  .disclaimer-agree-container {
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  
  .disclaimer-ok-btn {
    width: 270px;
  }

  .read-more {
    display: none;
  }

  .disclaimer-description .mobile {
    display: none;
  }

  .disclaimer-description .laptop {
    display: block;
  }

  // start hero container
  .hero-background-brain {
    bottom: 0;
    height: 80vh;
    margin-right: 0;
    max-width: none;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: calc(50% - 104px);
    transform: translate(0, calc(-50% + 104px));
    width: 80%;
    z-index: -1;
  }

  .hero-container {
    margin-top: 0;
    min-height: 100vh;
  }

  .hero-title {
    font-size: 5rem;
    line-height: 5.3rem;
  }

  .hero-description {
    font-size: 1.6rem;
  }

  .hero-button {
    margin-top: 110px;
    width: 270px;
  }
  // end hero container

  // block 2 start
  .summary-top-container {
    flex-direction: row;
  }

  .summary-top-container-left-block {
    flex: 2;
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-right: 2%;
  }

  .summary-top-container-right-block {
    flex: 1;
    font-size: 1.5rem;
    line-height: 1.9rem;
    margin-left: 2%;
  }

  .summary-top-container-right-block-description {
    font-size: 1.25rem;
    line-height: 1.6rem;
  }

  .summary-bottom-container {
    background: #131722;
    border-left: 10px solid #007BFF;
    margin-top: 110px;
    padding-bottom: 80px;
    padding-top: 60px;
  }

  .summary-bottom-container {
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .summary-bottom-container-block-title {
    font-size: 1.5rem;
    line-height: 1.9rem;
    width: 180px;
  }

  .summary-top-container-right-block-icon-container {
    min-width: 52px;
  }

  .summary-top-container-right-block-icon.first {
    height: 35px;
    width: 37px;
  }
  
  .summary-top-container-right-block-icon.second {
    height: 22px;
    width: 52px;
  }
  
  .summary-top-container-right-block-icon.third {
    height: 27px;
    width: 40px;
  }

  .summary-bottom-container-block-or {
    font-size: 1.5rem;
  }

  .summary-bottom-container-block-description {
    font-size: 1.8rem;
    width: 150px;
  }
  // block 2 end

  // block 6 start
  .sharing-background-hand {
    left: initial;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 50%;
    width: 90%;
    z-index: 1;
  }
  // block 6 end

  // block 10 start
  .video-container {
    padding: 50px;
  }
  // block 10 end

  // block 12 start
  .currencies-background-brain {
    width: 100%;
    left: 0;
    top: 18%;
  }
  // block 12 end

  // block 13 start
  .currencies-container {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .currencies-block {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 60px;
    padding-top: 40px;
  }

  .currencies-title {
    font-size: 1.9rem;
    line-height: 2.2rem;
    width: 300px;
  }

  .currencies-description,
  .exchanges-description {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
  }

  .currencies-icon-container,
  .exchanges-icon-container {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .exchanges-icon-container.first {
    min-width: 162.5px;
  }
  .exchanges-icon-container.second {
    min-width: 248px;
  }
  .exchanges-icon-container.third {
    min-width: 167px;
  }

  .exchanges-description {
    margin-top: 25px;
  }

  .currencies-icon {
    height: 60px;
    width: 60px;
  }

  .exchanges-icon {
    height: 50px;
  }
  // block 13 end

  footer {
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 80px 20px 80px 40px;
  }

  .footer-disclaimer {
    margin-bottom: 45px;
  }

  .disclaimer-container {
    background: #1F2433;
    justify-content: space-between;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    max-height: 750px;
    width: 600px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9992;
  }
}

@media (min-width: 900px) {
  // start 6 block
  .sharing-background-hand.wide {
    display: block;
    top: 55%;
  }
  .sharing-background-hand.thin {
    display: none;
  }
  // end 6 block

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 100%;
  }
  // block 12 end
}

@media (min-width: 1000px) {
  // start hero container
  .hero-background-brain {
    width: 70%;
  }
  // end hero container

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 100%;
  }
  // block 12 end
}

@media (min-width: 1100px) {
  // start hero container
  .hero-background-brain {
    width: 60%;
  }
  // end hero container

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 100%;
  }
  // block 12 end
}

@media (min-width: 1200px) {
  // start hero container
  .hero-background-brain {
    bottom: 0;
    height: 80vh;
    margin-right: 0;
    max-width: none;
    opacity: 0.7;
    position: absolute;
    right: -1.2rem;
    top: calc(50% + 50px);
    transform: translate(0, -50%);
    width: 50%;
  }
  // end hero container

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 100%;
  }
  // block 12 end
}

@media (min-width: 1400px) {
  // start 6 block
  .sharing-background-hand {
    top: 51%;
    width: 72%;
  }
  // end 6 block

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 100%;
  }
  // block 12 end
}

@media (min-width:1600px) {
  .wrapper {
    // padding: 40px 160px;
  }

  .title-font {
    font-size: 4.5rem;
  }

  .description-font {
    font-size: 1.9rem;
    line-height: 2.3rem;
    margin-top: 60px;
  }

  .standart-padding {
    padding-left: 160px;
    padding-right: 160px;
  }

  .disclaimer-container {
    padding: 50px;
  }

  // start hero container
  .hero-wrapper {
    max-height: 100vh;
    max-width: 100vw;
  }

  .hero-container {
    max-height: 940px;
    max-width: 1920px;
  }

  .hero-background-brain {
    max-width: none;
    margin-right: 0;
    width: auto;
  }

  .hero-background-left {
    bottom: 0;
    display: block;
    height: 100vh;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    z-index: -2;
  }

  .hero-background-right {
    bottom: 0;
    display: block;
    height: 100vh;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
  }

  .hero-logo {
    display: block;
    height: 115px;
    margin-left: 62pz;
    width: 406px;
  }

  .hero-title {
    font-size: 6rem;
    line-height: 6.5rem;
    margin-top: 65px;
  }

  .hero-button {
    width: 260px;
  }

  .hero-button.hero {
    position: relative;
  }

  .hero-button-background-1 {
    display: block;
    opacity: 0.5;
    min-width: 15vw;
    position: absolute;
    top: -5vh;
    transform: rotate(167.24deg);
    z-index: -1;
  }

  .hero-button-background-2 {
    display: block;
    min-width: 15vw;
    position: absolute;
    right: 15px;
    top: -5vh;
    transform: matrix(-0.97, -0.24, -0.24, 0.97, 0, 0);
    z-index: -1;
  }

  .hero-button-background-3 {
    display: block;
    height: 100px;
    position: absolute;
    top: -40px;
    width: 50vw;
    z-index: -1;
  }
  // end hero container

  // block 2 start
  .summary-top-container-left-block {
    font-size: 2.2rem;
    flex: 2;
    line-height: 2.5rem;
    margin-right: 5%;
  }

  .summary-top-container-right-block-string {
    margin-bottom: 25px;
  }

  .summary-top-container-right-block-icon.first {
    height: 35px;
    width: 37px;
  }
  
  .summary-top-container-right-block-icon.second {
    height: 22px;
    width: 52px;
  }
  
  .summary-top-container-right-block-icon.third {
    height: 27px;
    width: 40px;
  }
  
  .summary-bottom-container {
    padding-bottom: 20px;
    padding-top: 60px;
  }

  .summary-bottom-container-block-title {
    font-size: 1.9rem;
    width: fit-content;
  }

  .summary-bottom-container-block-description {
    font-size: 3rem;
    width: fit-content;
  }

  .summary-top-container-right-block-description {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }

  .summary-bottom-container-block-delimiter {
    border-top: 2px solid #007BFF;
    width: 100px;
  }
  // block 2 end

  // block 6 start
  .sharing-description {
    width: 60%;
  }
  // block 6 end

  // block 10 start
  .video-container {
    padding: 200px;
  }
  // block 10 end

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 80%;
  }
  // block 12 end

  // block 13 start
  .currencies-container {
    align-items: center;
    flex-direction: row;
    position: relative;
  }

  .currencies-block {
    align-items: center;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 60px;
    padding-top: 40px;
  }

  .currencies-block.left {
    margin-right: 10px;
  }

  .currencies-block.right {
    height: 320px;
    margin-left: 10px;
  }
  // block 13 end

  
  .disclaimer-container {
    background: #1F2433;
    justify-content: space-between;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    max-height: 650px;
    max-width: 1056px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    position: fixed;
    // right: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 9992;
  }

  footer {
    padding: 120px 160px;
  }

  .footer-disclaimer {
    margin-bottom: 80px;
  }
}

@media (min-width: 1921px) {
  // start hero container
  .hero-background-brain {
    height: 62vh;
  }

  // block 12 start
  .currencies-background-brain {
    bottom: 0;
    left: initial;
    right: -10%;
    top: initial;
    width: 80%;
  }
  // block 12 end

  footer {
    padding: 120px 0;
  }
}

.landing-sidebar-container {
  display: none;
  gap: 10px;
  grid-template-columns: 10px;
  grid-template-rows: 10px;
  left: 10px;
  line-height: 10px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.landing-sidebar-element {
  background: #595C64;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.2s linear;
}

.landing-sidebar-element.active {
  background: #00FF85;
}

.landing-sidebar-divider {
  background-color: #00FF85;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  transition: height 0.2s linear;
  width: 1px;
}

.landing-sidebar-divider.active {
  height: 30px;
}
.techWrapper{
  background-image: url("../../../assets/images/techNewImg.svg");
  background-repeat: no-repeat;
  width: 410px;
  height: 120px;
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  position: relative;
  left: 60px;
}
.apexcharts-tooltip {
  color: #000000;
  min-width: 70px;
  border-radius: 0!important;
  background: #fff!important;
}

.apexcharts-tooltip-box.apexcharts-tooltip-candlestick .value {
  width: 30px;
  overflow: hidden;
  display: inline-block;
  letter-spacing: 1.3px;
}
.techContent{
  width: 60px;
  text-align: center;
  position: relative;
  left: 35px;
  top: 10px;
}
.techWrapper h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 16px; 
  color: #FFFFFF;
 
}

.techWrapper p {
  font-size: 11px;
  line-height: 12px; 
  text-transform: uppercase;
  color: #FFFFFF; 
}
.logoSplit {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.techWrapper.showBrainPart {
  width: 130px;
  overflow: hidden; 
  opacity: 1;
  visibility: visible;
}

.techWrapper.showTextPart {
  width: 225px;
  overflow: hidden; 
  opacity: 1;
  visibility: visible;
  background-position: right;
}
.techWrapper.showTextPart .techContent {
  display: none;
}
.apexcharts-xaxis-label, .apexcharts-xaxis-label * {
  fill: rgb(255, 255, 255);
}
.trading-track-image-container {
  background: #1F2433;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
  margin: 0 auto 2rem;
}
.trading-track-image-container .navbar-sign-in-element {max-width: 450px!important;width: 450px!important;}
.trading-track-image-container .navbar-sign-in-container {
  width: 100%;
  margin-bottom: 30px;
  max-width: 1000px;
}
.navbar-sign-in-element.active {
  background: #007BFF;
  color: #fff;
}
.logoText:hover {
  filter: drop-shadow( 0px 0px 10px #007BFF);
}
.logoBrain:hover {
  filter: drop-shadow(0px 0px 10px #007BFF) brightness(1000);
}


@media (min-width: 1200px) {
  .landing-sidebar-container {
    display: grid;
    gap: 10px;
    grid-template-columns: 10px;
    grid-template-rows: 10px;
    left: 10px;
    line-height: 10px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }
   .standart-padding {
    padding-left: 40px;
    padding-right: 20px;
  }
}


@media (max-width: 991px) {
  .navbar-sign-in-element {
    font-size: 14px;
    width: 60px;
    height: 40px;
}
  .hero-container{
    padding-top: 0;
  }
  .summary-container{
    min-height: 80vh;
  }
  .trading-track-image-container {
    padding: 10px;
  }
  .trading-track-image-container .navbar-sign-in-container {
    width: 100%;
  }
  
  .trading-track-image-container  .navbar-sign-in-container .navbar-sign-in-element {
    width: 33.33%;
    max-width: 33.33%;
    height: 60px;
  }
  .apexcharts-text tspan {
    font-size: 12px;
  }
  .video-container {
    min-height: 50vh;
  }
  .currencies-container {
    min-height: 50vh;
  }
  .hero-container {
    min-height: 80vh;
  }
  .what-is-it-tailored-for-container {
    justify-content: center;
    min-height: 80vh;
  }
  .sharing-container {
    justify-content: center;
    min-height: 70vh;
    position: relative;
}
.description-font {
  font-size: 1.1rem;
}
.video-wrapper {
  width: 100%;
}
.creWrapper {
  height: auto!important;
  margin-top: 130px;
}
}

div#block-5 .hero-button {
  margin-top: 80px;
}

.trading-track-image-container .navbar-sign-in-delimiter {
  height: 60px;
  margin: 0;
  border-width: 2px;
}
.sharing-description.description-font {
  width: 100%;
  max-width: 800px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loader {position: fixed;text-align: center;left: 0;right: 0;bottom: 0;top: 0;margin: auto;display: flex;align-items: center;justify-content: center;background:#000000e6;z-index: 999999999999;}
