:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

.profile-confirm-wrapper {
  display: grid !important;
  margin-bottom: 20px;
}

.form-access {
  background: #1F2433;
  margin-left: auto;
  margin-right: auto;
}

.qr-mobile-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.qr-mobile-secret {
  color: #fff;
  margin-top: 40px;
  text-align: center;
}

.profile-confirm-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.profile-confirm-description {
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}

.profile-confirm-button {
  box-shadow: 0px 3px 10px rgba(0, 123, 255, 0.5);
}

.profile-confirm-brain-container {
  display: none;
}

.profile-confirm-pre-description {
  padding: 30px 30px 0 30px;
}

@media (min-width:600px) {
  .profile-confirm-title {
    font-size: 3rem !important;
  }

  .profile-confirm-description {
    font-size: 1.5rem !important;
  }

  .profile-confirm-otp-code {
    margin-top: 0px;
  }

  .profile-confirm-button {
    margin-top: 40px !important;
  }
}

@media (min-width:1600px) {
  .qr-mobile-wrapper {
    display: none;
  }

  .profile-confirm-brain-container {
    display: block;
  }

  .profile-confirm-wrapper {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    min-height: calc(100vh - 168px);
  }

  .profile-confirm-wrapper .block {
    aspect-ratio: 1;
  }

  .form-access {
    align-items: flex-start;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 10px 0 #131722;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    min-width: 350px;
    padding: 30px;
    width: 650px;
  }

  .form-access form {
    border: 0 !important;
    box-shadow: none !important;
    display: grid;
    justify-content: start;
    width: 100%;
  }

  .form-control {
    border: 1px solid #fff !important;
    font-size: 1.5rem !important;
    padding: 1.8rem !important;
    width: 473px;
  }

  .profile-confirm-button {
    font-size: 1.5rem;
    line-height: 34px;
    margin-top: 50px;
    width: 260px !important;
  }

  .profile-confirm-brain-container {
    align-items: center;
    background: #1f2433;
    display: flex;
    height: 80vh;
    justify-content: center;
    max-height: 650px;
    overflow: hidden;
    position: relative;
  }

  .profile-confirm-brain {
    left: 5%;
    max-width: 200%;
    position: absolute;
    right: 5%;
    top: 18%;
    width: 90%;
  }

  .otp-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .qr-desktop-secret {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
  }

  .profile-confirm-qr-code {
    max-width: 50%;
  }
}
