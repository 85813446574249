* {
  box-sizing: border-box;
}

.faq-container {
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  max-width: 1620px;
  padding: 10px;
  white-space: pre-line;
  width: 100%;
}

.faq-title {
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  padding: 12px;
  flex-direction: revert;
  justify-content: space-between;
}

.faq-text {
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  padding: 20px;
}
.faq-text-privacy {
  text-align: justify;
}
.faq-container .accordion > button {
  border: none;
  text-align: left;
  background: #1F2533;
  margin-top: 20px;
}
 
.faq-title img {
  width: 100%;
  max-width: 20px;
}

