.notfound-container {
  align-items: center;
  color: #fff;
  display: flex;
  height: calc(100vh - 62px);
  justify-content: center;
  position: relative;
}

.notfound-background-container {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.notfound-background-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.notfound-background {
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.notfound-background-left {
  bottom: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.notfound-background-right {
  bottom: 0;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
}

.notfound-image {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.notfound-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  text-transform: uppercase;
}

.notfound-description {
  font-size: 1rem;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.notfound-homepage-btn {
  background: #007BFF;
  border-radius: 3px;
  box-shadow: 0px 3px 10px rgba(0, 123, 255, 0.5);
  color: #FFF;
  font-size: 1.25rem;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  text-align: center;
  width: 260px;
}

.notfound-homepage-btn:hover {
  color: #fff;
  text-decoration: none;
}

@media (min-width:600px)  {
  .notfound-title {
    font-size: 3rem;
  }

  .notfound-description {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}

@media (min-width:1600px)  {
  .notfound-container {
    height: calc(100vh - 127px);
  }
  .notfound-title {
    font-size: 3rem;
  }

  .notfound-description {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}
