:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

.dashboard-wrapper {
  overflow-x: auto;
}

.summary-info-block {
  color: #fff;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 1150px;
  flex-direction: revert;
  justify-content: space-around;
}

.summary-info-block div {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.summary-info-block  div > span {
    color: #00FF85;
    margin-left: 20px;
}
.summary-info-block div img{
  margin-right: 15px;
}

table.create-bot-table th,
table.bots-table th {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #00FF85;
  height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.bots-table td {
  height: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  vertical-align: middle;
  font-size: 18px; 
}
 

.dashboard-bot-input {
  padding: 3px;
}

.create-bot-table {
  margin-left: auto;
  margin-right: auto;
}

.bots-table-title {
  color: #FFF;
  font-size: 48px; 
  text-align: center;
}
.botConfigWrapper {
  background: #1F2533;
  margin: 80px 0;
  padding: 60px 0;
}
.bots-table {
  margin-left: auto;
  margin-right: auto;
  width: 1560px;
  background: #131722;
}
.whatsNew p {
  font-size: 18px;
  line-height: 26px; 
  text-decoration-line: underline;
  color: #007BFF;
  text-align: center;
  cursor: pointer;
}

.whatsNew {
  margin-bottom: 20px;
}
.bots-table > th {
  color: #fff;
}
.formConfig {
  flex-direction: row;
  margin: auto;
}

.form-group label {
  font-size: 24px;
  line-height: 36px; 
  color: #FFFFFF;
}


.formConfig .form-group .form-control {
  width: 270px;
  font-size: 18px!important;
  line-height: 36px;
  color: #FFFFFF;
  height: 60px;
  padding: 0 1.8rem !important;
}

.btn.btn-info {
  background: #007BFF;
  box-shadow: 0px 3px 10px rgba(0, 123, 255, 0.5);
  border-radius: 3px;
  border-color: #007BFF;
}

.formConfig .form-group {
  padding: 0 15px;
  display: flex;
}

.formConfig .form-group .btn-info {
  height: 60px;
  width: 300px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 45px;
}
.create-bot-btn {
  color: #fff;
  background: #26de81;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #26de81ad;
  padding: 3px 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.bot-action-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.bot-control-btn {
  color: #26de81;
  cursor: pointer;
  height: 24px;
}
.bot-control-btn.delete {
  color: #ff231f;
}

.create-bot-error {
  color: red;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.support-link {
  background: #26de81;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 0px #26de81ad;
  color: #fff;
  cursor: pointer;
  display: flex;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-decoration: none;
  text-transform: uppercase;
  width: 260px;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1em;
}
.support-link:hover {
  color: #fff;
  text-decoration: none;
}

.dashboard-bot-status {
  position: relative;
}

.dashboard-bot-status-description {
  background: #fff;
  bottom: 0;
  color: #000;
  right: 0;
  position: absolute;
}

.dashboard-bot-status-desktop {
  display: none;
}
.formConfig .form-group .form-control::placeholder { 
  color: #fff;
  opacity: 1; 
}

.formConfig .form-group .form-control:-ms-input-placeholder { 
  color: #fff;
}

.formConfig .form-group .form-control::-ms-input-placeholder {
  color: #fff;
}




@media (max-width: 991px) {
  .summary-info-block {
    display: block;
    width: 100%;
  }

  .formConfig {
      flex-direction: column;
      width: 100%;
  }

  .formConfig .form-group .form-control {
    width: 100%;
    padding: 0 1rem !important;
    height: 50px;
    font-size: 14px!important;
  }

  .formConfig .form-group .btn-info {
      margin-top: 15px;
      font-size: 20px;
      width: 100%;
  }

  .botConfigWrapper {
      padding: 30px 0 0;
  }

  .summary-info-block {
    display: block;
  }

  .formConfig {
    flex-direction: column;
    width: 100%;
  }

  .formConfig .form-group .form-control {
    width: 100%;
    padding: 0 1rem !important;
    height: 50px;
    font-size: 14px!important;
  }

  .formConfig .form-group .btn-info {
    margin-top: 15px;
    font-size: 20px;
    width: 100%;
  }

  .botConfigWrapper {
    padding: 30px 0 0;
  }

  .summary-info-block div {
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-bottom: 16px;
    padding: 0 10px;
  }

  .summary-info-block div img {
    width: 25px;
  }
  .bots-table-title {
    font-size: 30px;
  }
  table.bots-table {
    margin: 0 10px;
  }
  .whatsNew p {
    font-size: 16px;
    line-height: 20px; 
}
.logoBrain {
  width: 50px;
}
.faq-title {
  font-size: 14px;
  line-height: 22px;
}
.faq-text {
  font-size: 15px;
  line-height: 22px;
  padding: 20px 5px;
}
}

