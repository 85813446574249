.registration-completed-container {
  align-items: center;
  color: #fff;
  display: flex;
  height: calc(100vh - 62px);
  justify-content: center;
  position: relative;
}
.registration-completed-background-wrapper-img{
  background-image: url("../../../assets/images/mainBgImg.svg");
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.registration-completed-background-container {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.registration-completed-background-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
  padding-top: 100px;  
}

.registration-completed-background {
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.registration-completed-background-left {
  bottom: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.registration-completed-background-right {
  bottom: 0;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
}

.registration-completed-image-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 40%;
}

// .registration-completed-image {
//   margin-left: auto;
//   margin-right: auto;
//   width: 60%;
// }

.registration-completed-image-checkbox {
  position: absolute;
}

.registration-completed-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  text-transform: uppercase;
}

.registration-completed-description {
  font-size: 1rem;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.registration-completed-homepage-btn {
  background: #007BFF;
  border-radius: 3px;
  box-shadow: 0px 3px 10px rgba(0, 123, 255, 0.5);
  color: #FFF;
  font-size: 1.25rem;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  text-align: center;
  width: 260px;
}

.registration-completed-homepage-btn:hover {
  color: #fff;
  text-decoration: none;
}

@media (min-width:600px)  {
  .registration-completed-title {
    font-size: 2.5rem;
  }

  .registration-completed-description {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}

@media (min-width:1600px)  {
  .registration-completed-container {
    height: calc(100vh - 127px);
    padding-top: 160px;
  }
  .registration-completed-title {
    font-size: 2.5rem;
  }

  .registration-completed-description {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}
