html {
  scroll-behavior: smooth;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.mtb15 {
  margin: 15px 0;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.ptb70 {
  padding: 70px 0;
}
.pb70 {
  padding-bottom: 70px;
}
.pt70 {
  padding-top: 70px;
}
.pb40 {
  padding-bottom: 40px;
}
body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #007BFF;
  border: solid #007BFF 2px;
  border-radius: 5px;
}
.dropdown-toggle::after {
  vertical-align: 1px;
}
// thead tr th {
//   padding: 8px 15px !important;
// }
// thead th {
//   color: #758696;
//   font-size: 13px;
//   font-weight: 600;
//   border: 0 !important;
// }
// tbody tr {
//   color: #4a4a4a;
// }
// tbody tr:hover {
//   background: #f6f8f9;
// }
.input-group-text,
.form-control {
  border-radius: 5px;
}
.table tbody + tbody {
  border: none;
}
.heading {
  background: #f5f9fc;
  font-size: 14px;
  font-weight: 400;
  padding: 13px;
  margin: 0;
  color: #4a4a4a;
}
.green {
  color: #26de81;
}
.red {
  color: #ff231f;
}
// .table td,
// .table th {
//   border-top: 1px solid #f0f3fa;
// }
.light-bb,
.input-group-text {
  border: 1px solid #e0e3eb;
}
.dark-bb {
  border-bottom: 1px solid #2a2e39;
}
#darkDepthChart,
#lightDepthChart {
  width: 100%;
  height: 349px;
}
.sm-gutters {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.sm-gutters > .col,
.sm-gutters > [class*='col-'] {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #18214d59;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #18214d59;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #18214d59;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #18214d59;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
// .nav-tabs .nav-link.active,
// .nav-tabs .nav-link:hover {
//   border-color: transparent;
// }
.slick-slide > div {
  margin: 0 8px;
}
.slick-list {
  margin: 0 -8px;
}
.page-content {
  margin: 40px 0 100px;
}

.page-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 60px;
}

.page-content p {
  font-size: 16px;
}
.news-details {
  padding: 100px 0;
}

.news-details h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.news-details p {
  font-size: 16px;
}


input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}